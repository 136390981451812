import $ from 'jquery';
import React from 'react';
import Chart from "react-google-charts";
import {UserToken,getUserObjData,getAccessFunctions} from '../function_lib/userToken';
import {CallMapMethod, GetDateDifferecec,CallMapMethod_CBack,_getInitParam} from '../function_lib/common_lib';
import Header from './header';
import Footer from './footer';
import LogoImg from '../assets/images/logo_p_small.png';
import defaultPic from '../assets/images/9.png';

import Upcoming_Events from '../components/upcoming_events';
import Emp_On_Leave from '../components/emp_on_leave';
import Broadcast from '../components/broadcast';
import Mobile_Widgets from '../components/mobile_widgets';
import { Link } from 'react-router-dom';

import './dashboard_new.css';

//import UserManual from '../assets/Operational_Instructions.pdf';

let loginUser="";
let userObject="";
let companyName="";
let userType="";
let clsDashboardNew;

let _chkOrderCall=false;
let _chkAnalyticsCall=false;
let _chkCmsCall=false;
let _initSettings={};
class Comp_Dashboard extends React.Component{
    forceUpdateHandler(){
        this.forceUpdate();
      };
    constructor(props){
        super(props);
        clsDashboardNew=this;
        _initSettings=_getInitParam();
        //alert(JSON.stringify(_initSettings.app_settings.settings.dashboard));
        _initSettings=_initSettings.app_settings.settings.dashboard;
        this.state={creditBalance:[]};
        //this.state={no_entities:0,no_clients:0,no_vendors:0}
        this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
        userType="0";
        
        if(localStorage.getItem("userData")!=null){
          userObject= getUserObjData();
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          companyName=userObject[0].first_name;
      }

      let ret= getAccessFunctions();
      //alert(ret);
     
    }
    getCreditBalance(){
      var map=[];
      map.push({name:"curl_type",value:"getCreditBalance"});
      map.push({name:"res_func",value:"callBackrrr"});
      map.push({name:"local_token",value:userObject[0].app_user_id});
      //map.push({name:"acc_inf_id",value:userObject[0].app_user_id});
      
      map["res_func"]="callBackrrr";
      map["curl_type"]="getCreditBalance";
      CallMapMethod_CBack(clsDashboardNew,map,function(data,msg){
        //alert(data);
          if(data!=="0" && data!==""){
              let listData=JSON.parse(data);
              var strData="";
              clsDashboardNew.setState({creditBalance:listData},()=>{
                clsDashboardNew.forceUpdateHandler();
              })
            }
      });
}

      componentDidMount(){
        //this.getCreditBalance();
        $("#divEmployee").show();
        this.forceUpdateHandler();
      }
   //Get no of Entities/Client/Vendos
     
   
     

    render(){
        return(<div className="wrapper">
            <Header/>
            {/* <Mobile_Widgets/> */}
            <div class="content" style={{"min-height":"150px"}}>
            <div class="container-fluid mt-4">
                   {/* new acta component */}
                   <div className="row">
                    <div className="col-md-12">
                      
                      <div className="row">
                      {_initSettings["welcome_msg"]==="yes"?
                        <div className="col-md-12">
                          Welcome of Fast RC Direct
                        </div>:''}
                    {/* user compoentnet  */}
            {/* component type */}
          
          {/* invoice order view */}
          {_initSettings["orders"]==="yes"?
             <div className="col-md-4">
            <div className="card card-widget widget-user-2">
              <div className="widget-user-header bg-warning">
                <div className="widget-user-image">
                
                 <span className="info-box-icon text-red bg-info1 elevation-11"><i className="fa fa-cubes fa-3x" aria-hidden="true"></i></span>
                  
                 
                </div>
                <h3 className="widget-user-username"></h3>
                <h5 className="widget-user-desc">{"Study Topics"}</h5>
              </div>
              <div className="card-footer p-0">
                <ul className="nav flex-column">
                  <li className="nav-item">
                    <Link to="../study/marpol" className="nav-link">
                      MARPOL <span className="float-right badge bg-primary">{"10"}</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                  <Link to="../study/solas" className="nav-link">
                      SOLAS <span className="float-right badge bg-primary">{"10"}</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                  <Link to="../study/cardinal_marks" className="nav-link">
                  CARDINAL MARKS <span className="float-right badge bg-primary">{"10"}</span>
                    </Link>
                   
                  </li>
                  <li className="nav-item">
                  <Link to="../study/compass_calibration" className="nav-link">
                  COMPASS CALIBRATION <span className="float-right badge bg-primary">{"10"}</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                  <Link to="../study/imdg" className="nav-link">
                  IMDG <span className="float-right badge bg-primary">{"10"}</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                  <Link to="../study/pzx_triangle" className="nav-link">
                  PZX TRIANGLE <span className="float-right badge bg-primary">{"10"}</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div> 
    :''}
          {/* end of invoice order view */}
            
            {/* dau view */}
            {_initSettings["dau"]==="yes"?
           <div className="col-md-4">
            <div className="card card-widget widget-user-2">
              <div className="widget-user-header bg-warning">
                <div className="widget-user-image">
                
                 <span className="info-box-icon text-red bg-info1 elevation-11"><i className="fa fa-cogs fa-3x" aria-hidden="true"></i></span>
                  
                 
                </div>
                <h3 className="widget-user-username"></h3>
                <h5 className="widget-user-desc">{"Performance Analytics"}</h5>
              </div>
              <div className="card-footer p-0">
                <ul className="nav flex-column">
                  <li className="nav-item">
                    <a href="#" className="nav-link">
                      Currently Online Users <span className="float-right badge bg-primary">{"10"}</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="nav-link">
                      Todays Visitors <span className="float-right badge bg-danger">{"8"}</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="nav-link">
                      Yesterday Visitors <span className="float-right badge bg-success">{"9"}</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="nav-link">
                      Total Order(till date) <span className="float-right badge bg-success">{"27"}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          :''}
{/*end dau view */}
            {/* end of component tyep */}
</div>
</div>
{/* end of compaign */}
</div>
                {/* end of new acta component */}

{/* <!-- /.content --> */}
</div>
      </div> 
        <Footer/>
        
      </div>)
    }
}
export default Comp_Dashboard